import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./navbar.module.css";
import MenuIcon from "./icons/MenuIcon";
import CloseIcon from "./icons/CloseIcon";

const Navbar = () => {
  const [isMobMenuOpen, setIsMobMenuOpen] = useState(false);
  const location = useLocation();

  const handleMobMenu = () => {
    setIsMobMenuOpen((prev) => !prev);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsMobMenuOpen(false);
  };

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.slice(1);
      scrollToSection(sectionId);
    }
  }, [location]);

  const navLinks = [
    { name: "Products", id: "products" },
    { name: "How Ÿ works", id: "how-it-works" },
    { name: "Resources", id: "https://docs.yoracle.ai/", external: true },
    { name: "Tokenomics", id: "tokenomics" },
  ];

  const NavLink = ({ name, id, external }) =>
    external ? (
      <a
        href={id}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    ) : (
      <Link
        to={`#${id}`}
        className={styles.link}
        onClick={() => scrollToSection(id)}
      >
        {name}
      </Link>
    );

  const NavLinkM = ({ name, id, external }) =>
    external ? (
      <a
        href={id}
        className={styles.mobLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    ) : (
      <Link
        to={`#${id}`}
        className={styles.mobLink}
        onClick={() => scrollToSection(id)}
      >
        {name}
      </Link>
    );
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mobNavContainer}>
        <div className={styles.mobLogo}>
          <img
            src="/images/headerLogo.svg"
            className={styles.moblogoImg}
            alt="Logo"
          />
        </div>
        <button onClick={handleMobMenu}>
          <MenuIcon />
        </button>
      </div>

      {isMobMenuOpen && (
        <div className={styles.mobMenuContainer}>
          <div className={styles.mobHeader}>
            <div className={styles.mobLogo}>
              <img
                src="/images/headerLogo.svg"
                className={styles.moblogoImg}
                alt="Logo"
              />
            </div>
            <button onClick={handleMobMenu}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.mobLinks}>
            {navLinks.map((link) => (
              <NavLinkM key={link.id} {...link} />
            ))}
          </div>
        </div>
      )}

      {/* Desktop navigation */}
      <div className={styles.navContainer}>
        <div className={styles.logo}>
          <img
            src="/images/headerLogo.svg"
            className={styles.logoImg}
            alt="Logo"
          />
        </div>
        <div className={styles.links}>
          {navLinks.map((link) => (
            <NavLink key={link.id} {...link} />
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <Link to='https://dashboard.yoracle.ai' className={styles.button}>
            Oracle
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
