import React, { useState, useRef } from "react";
import styles from "./DataYouCanTrust.module.css";

const faqs = [
  {
    id: 1,
    header: "Unified Market Overview",
    text: `Access Data insights on the current market state and AI model predictions for various assets.`,
  },
  {
    id: 2,
    header: "Back-tested trading signals",

    text: `Get Detailed analysis of predefined trading strategies, including historical performance and individual trades`,
  },
  {
    id: 3,
    header: "Dynamic Model Sentiment",

    text: `Experience Visualized market sentiment for each symbol from multiple AI model perspectives.`,
  },
  {
    id: 4,
    header: "Seamless Interaction",

    text: `Monitor data, analyze patterns, and derive your decisions based on data from recent market insights.`,
  },

  {
    id: 5,
    header: "Optimized Framework",

    text: `Unlock market insights with unparalleled efficiency and performance. `,
  },

];
const Accordion = ({ faq, isOpen, onClick }) => {
  return (
    <div className={styles.accordionContainer}>
      <h4 onClick={onClick} className={isOpen ? styles.active : ""}>
        {faq.header}{" "}
        <span className={`${styles.arrow} ${isOpen ? styles.down : ""}`}>
          <img className={styles.arrowStyle} src="/images/arrowDown.svg" />
        </span>
      </h4>
      <div className={`${styles.content} ${isOpen ? styles.show : ""}`}>
        <div className={styles.subHeading}>{faq.subHeading}</div>

        <p>{faq.text}</p>
      </div>
    </div>
  );
};

export default function Allinone({ id }) {
  const [openFaqId, setOpenFaqId] = useState(null);

  return (
    <div id={id} className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.mainHeading}>
          Find your edge in real-time.
          <span> DATA YOU CAN TRUST.</span>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.leftContainer}>
            {/* <h2>React Accordion</h2> */}
            {faqs.map((faq) => (
              <Accordion
                key={faq.id}
                faq={faq}
                isOpen={faq.id === openFaqId}
                onClick={() =>
                  setOpenFaqId(faq.id !== openFaqId ? faq.id : null)
                }
              />
            ))}
          </div>

          <div className={styles.rightContainer}>
            <img src="/images/intelligenceModel2.png" />
          </div>
        </div>
      </div>
    </div>
  );
}
