import { useState, useEffect, useRef } from "react";

import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styles from "./modal.module.css";

const ModalExample = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accepted, setAccepted] = useState(false);
  const [newState, setNewState] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const joinRef = useRef(null);
  const [isJoinVisible, setIsJoinVisible] = useState(false);
  const initialRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsJoinVisible(true);
          observer.disconnect(); 
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // if (isOpen) {
    const modalContent = joinRef.current;

    if (modalContent) {
      observer.observe(modalContent);
    }
    // }

    return () => {
      observer.disconnect(); 
    };
  }, [newState]);

  useEffect(() => {
    if (isOpen) {
      setNewState(!newState);
    }
  }, [isOpen]);

  useEffect(() => {
    const acceptRejectTag = localStorage.getItem("acceptRejectTag");
    if (acceptRejectTag === "reject") {
      window.location.href = "https://www.google.com"; // Redirect to Google
    } else if (acceptRejectTag === "accept") {
      if (isCheckboxChecked) {
        setAccepted(true);
      } else {
        console.log("not enabled");
      }
    } else {
      onOpen(); // Show the modal if no tag found
    }
  }, [onOpen]);

  const handleAccept = () => {
    localStorage.setItem("acceptRejectTag", "accept");
    setAccepted(true);
    onClose();
  };

  const handleReject = () => {
    // localStorage.setItem("acceptRejectTag", "reject");
    window.location.href = "https://www.google.com"; // Redirect to Google
  };

  const redirectToURL = (url) => {
    window.open(url, "_blank");
  };

  if (accepted) {
    return null; 
  }
  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg={"rgba(0, 0, 0, 0.7)"} />
        <ModalContent
          bg="radial-gradient(73.86% 104.92% at 50.1% 14.77%, rgba(16, 24, 43, 1) 3.13%, rgba(15, 20, 32, 1) 100%)"
          color="white"
          paddingX={["10px,30px,30px,30px"]}
          paddingY={["20px"]}
          width="80%"
          maxW="700px"
        >
          <ModalHeader ref={initialRef}>Terms of Service</ModalHeader>

          <ModalBody opacity={0.5} className={styles.modalBody}>
            <div
              style={{
                height: "50vh",
                overflowY: "scroll",

                // scrollbarColor: " #aaa white !important",
              }}
            >
              <div style={{ marginLeft: "25px" }}>
                <ol
                  style={{
                    listStyleType: "decimal !important",
                    fontSize: "11pt",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <li
                    style={{
                      listStyleType: "decimal !important ",
                      fontSize: "11pt",
                    }}
                  >
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        By accessing our website or using Ÿ Project services,
                        you agree to abide by these Terms. We may update these
                        Terms without prior notice.
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        You must be at least 18 years old to use our
                        services.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Users must comply with laws applicable to their
                        jurisdiction. The regulation of crypto assets and
                        platforms is uncertain in many jurisdictions and Ÿ
                        Project cannot be held responsible for compliance with
                        legal rules of countries from which customers, on their
                        own initiative, access the Services. Moreover, changes
                        in rules applicable to crypto assets may considerably
                        impact on the prices of those assets and are
                        unpredictable.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Access from restricted locations such as the USA, UAE,
                        or China is prohibited.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Our service, Ÿ, uses advanced AI to predict financial
                        market trends. We aim for precision in market
                        predictions, though updates depend on market conditions.
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Our native token, $YAI, is available on Uniswap, but do
                        not expect any return on your trades. Most crypto
                        traders lose money. Prior to deciding to acquire $YAI
                        tokens, we strongly advise thorough consideration of
                        potential risks, including consultation with legal and
                        financial advisors if necessary.&nbsp;
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Automated trading systems, bots, and market manipulation
                        are strictly prohibited. Violation of the bot usage
                        restriction may result in consequences such as freezing
                        or confiscation of project tokens, suspension of access
                        to the Services. The project reserves the right to
                        investigate any suspicious or fraudulent activities
                        related to the project token and take appropriate action
                        based on its findings.
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        All intellectual property rights belong to the Ÿ
                        Project. Users must not violate these rights.
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        We do not provide financial advice. Content accessed
                        through our site is not endorsed or guaranteed by us.The
                        User is the only party who can determine whether a
                        trade, trading strategy or related transaction is
                        appropriate based on his or her personal objectives,
                        financial situation and risk tolerance, and shall be
                        solely responsible for any losses or liabilities that
                        may result.
                      </span>
                    </p>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        We and our related parties are not liable for any
                        damages incurred while using our services.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
              </div>
              <div style={{ fontSize: "11pt" }}>
                <span style={{ fontSize: "11pt" }}>
                  Trading involves substantial risk. Only risk capital should be
                  used, and past performance does not guarantee future results.
                </span>

                <div style={{ fontSize: "11pt", marginTop: "20px" }}>
                  {/* <br /> */}
                  By accepting you agree to abide by these Terms, along with any
                  additional policies and procedures posted on the Site or our
                  official channels to the following terms:
                </div>
                {/* <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={redirectToURL}
                >
                  https://www.yoracle.ai/legal
                </span> */}
                <ol style={{ marginLeft: "10%" }}>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/terms-of-services"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/terms-of-services"> */}
                    Terms of Services
                    {/* </a> */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/refund-policy"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/refund-policy"> */}
                    Refund Policy
                    {/* </a> */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/discretionary-policy"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/discretionary-policy"> */}
                    Discretionary Policy
                    {/* </a>/ */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/yai-token-disclaimer"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/yai-token-disclaimer"> */}
                    YAI Token Disclaimer
                    {/* </a> */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/trading-disclaimer"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/trading-disclaimer"> */}
                    Trading Disclaimer
                    {/* </a> */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/risk-disclaimer"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/risk-disclaimer"> */}
                    Risk Disclaimer
                    {/* </a> */}
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      fontSize: "11pt",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      redirectToURL(
                        "https://docs.yoracle.ai/legal/privacy-policy"
                      )
                    }
                  >
                    {/* <a href="https://docs.yoracle.ai/legal/privacy-policy"> */}
                    Privacy Policy {/* </a> */}
                  </li>
                </ol>
                <div ref={joinRef} style={{ marginTop: "20px" }}>
                  We reserve the right to modify these Terms or suspend our
                  services at any time.
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter alignItems="flex-start" flexDir={"column"} pt="5px">
            {/* <div style={{ display: "flex", marginBottom: "10px" }}>
              <Checkbox
                _focus={{ outline: "none" }} // Remove blue highlight on tap
                outline={"none"}
                isChecked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                colorScheme="whiteAlpha"
                mr={2}
                className={styles.checkbox}
                // mb={3}
              ></Checkbox>
              <div style={{ fontSize: "12px" }} boxShadow={"none !important"}>
                I have reviewed and agree to the Terms of Service.
              </div>
            </div> */}
            <Checkbox
              opacity={0.8}
              isChecked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              colorScheme="whiteAlpha"
              mr={2}
              mb={3.5}
              className={styles.checkbox}
            >
              <div style={{ fontSize: "12px" }} boxShadow={"none !important"}>
                I have reviewed and agree to the Terms of Service.
              </div>
            </Checkbox>
            <Flex justifyContent="flex-start">
              <Button
                variant={"solid"}
                onClick={handleAccept}
                outline={"none"}
                // isDisabled={!isJoinVisible}
                isDisabled={!isCheckboxChecked} // Disable the button if the checkbox is not checked
                boxShadow={"none !important"}
              >
                Accept
              </Button>
              <Button
                ml={2}
                colorScheme="transparent"
                outline={"none"}
                boxShadow={"none !important"}
                mr={3}
                onClick={handleReject}
              >
                Reject
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalExample;
