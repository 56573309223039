import React, { useState, useEffect, useRef } from "react";
import styles from "./RealtimeAiPowered.module.css";
import ArrowRight from "../Icons/ArrowRight";
import ArrowLeft from "../Icons/ArrowLeft";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./slider.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { slidesRealtimeAiPowered } from "../data";



export default function RealtimeAiPowered() {
  const joinRef = useRef(null);
  const textRef = useRef(null);

  const [displayedText, setDisplayedText] = useState("");
  const [startTyping, setStartTyping] = useState(false);

  const text =
    "Trade to win. Discover price moves. Get any market data you need - see the future.";
  const typingSpeed = 50; // Speed in milliseconds

  useEffect(() => {
    if (startTyping) {
      let index = 0;

      // Function to type text
      const typeText = () => {
        if (index < text.length) {
          setDisplayedText(text.slice(0, index + 1));
          index++;
          setTimeout(typeText, typingSpeed);
        }
      };

      typeText();
    }
  }, [startTyping]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartTyping(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.sliderContainer}>
          <div className={styles.border}>
            <div className={styles.slider}>
              <Swiper
                className="aiPowered"
                slidesPerView={1}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                modules={[Pagination, Autoplay, Navigation]}
              >
                {slidesRealtimeAiPowered.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className={styles.Mcard}>
                      <img src={slide.image} alt={slide.alt} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className={styles.sliderBorderArrow}>
            <div className={styles.sliderArrow}>
              <div className={`${styles.iconContainerLeft} swiper-button-prev`}>
                <div style={{ zIndex: 100 }}>
                  <ArrowLeft />
                </div>
              </div>
              <div
                className={`${styles.iconContainerRight} swiper-button-next`}
              >
                <div style={{ zIndex: 100 }}>
                  <ArrowRight color="#C2EA2B" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.heading}>
            <span> Accurate Real-Time</span> Market insights and Predictions
            <div className={styles.subHeading} ref={textRef}>
              <p>
                {displayedText.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>

          <Link to={"https://dashboard.yoracle.ai/"} className={styles.button}>
            Access Now
          </Link>
        </div>
      </div>
    </div>
  );
}
